const load = () => {
    'use strict';

    !function() {
        let t = window.driftt = window.drift = window.driftt || [];

        if (!t.init) {
            if (t.invoked) return (window.console && console.error && console.error("Drift snippet included twice."));
            t.invoked = !0, t.methods = [ "identify", "config", "track", "reset", "debug", "show", "ping", "page", "hide", "off", "on" ],
            t.factory = function(e) {
                return function() {
                    let n = Array.prototype.slice.call(arguments);
                    return n.unshift(e), t.push(n), t;
                };
            }, t.methods.forEach(function(e) {
                t[e] = t.factory(e);
            }), t.load = function(t) {
                let e = 3e5, n = Math.ceil(new Date() / e) * e, o = document.createElement("script");
                o.type = "text/javascript", o.async = !0, o.crossorigin = "anonymous", o.src = "https://js.driftt.com/include/" + n + "/" + t + ".js";
                let i = document.getElementsByTagName("script")[0];
                i.parentNode.insertBefore(o, i);
            };
        }
    }();

    drift.SNIPPET_VERSION = '0.3.1';
    drift.load('5hrxis5sp2ss');
};

const show = () => {
    drift.api.widget.show();
}

const hide = () => {
    drift.api.widget.hide();
}

const toggleChat = () => {
    drift.api.toggleChat();
}

const openChat = () => {
    drift.api.openChat();
}

const goToConversation = (id) => {
    drift.api.goToConversation(id);
}

const isLoaded = () => {
    return window.driftt && window.driftt.invoked;
}

const attachEvent = (eventName, callback) => {
	if(!isLoaded) {
		console.info('Drift is not yet loaded');
	}

	drift.on(eventName, callback);
}

export default { load, show, hide, toggleChat, openChat, goToConversation, isLoaded, attachEvent };
