import { compose } from 'helpers/utils';

const exists = (maybe) => typeof maybe !== 'undefined' && maybe !== null;

export const cleanCamelCase = (camelCase) => {
	let newText = '';

	if (camelCase === null || camelCase === '') {
		return camelCase;
	}

	camelCase = camelCase.trim();

	for (let i = 0; i < camelCase.length; i++) {
		if ((/[A-Z]/).test(camelCase[i]) && i !== 0 && (/[a-z]/).test(camelCase[i - 1])) {
			newText += ' ';
		}

		if (i === 0 && (/[a-z]/).test(camelCase[i])) {
			newText += camelCase[i].toUpperCase();
		} else {
			newText += camelCase[i];
		}
	}

	return newText;
};

// Returns the plural version of a given word if the value is not 1. The default suffix is 's'.
export const pluralize = (number, singular, plural) => {
	if (!(exists(number) && exists(singular))) {
		return null;
	}

	plural = exists(plural) ? plural : `${singular}s`;

	return parseInt(number, 10) === 1 ? singular : plural;
};

// Converts a list of items to a human readable string with an optional limit.
export const oxford = (items, limit, limitStr) => {
	const numItems = items.length;

	let limitIndex;
	if (numItems < 2) {
		return String(items);
	} else if (numItems === 2) {
		return items.join(' and ');
	} else if (exists(limit) && numItems > limit) {
		const extra = numItems - limit;
		limitIndex = limit;
		limitStr = exists(limitStr) ? limitStr : `, and ${extra} ${this.pluralize(extra, 'other')}`;
	} else {
		limitIndex = -1;
		limitStr = `, and ${items[numItems - 1]}`;
	}

	return items.slice(0, limitIndex).join(', ') + limitStr;
};

// Converts a string into alpha-numeric and hypens
export const urlFriendly = (string) => {
	return string
		.replace(/[^\w ]+/g, '')
		.replace(/_/g, '-')
		.replace(/ +/g, '-')
		.toLowerCase();
};

export const camelize = (key) => {
	return key.replace(/_\w/g, (m) => m[1].toUpperCase());
};

export const camelCaseToSnakeCase = (string) => {
	return string.replace(/[\w]([A-Z])/g, (m) => {
		return `${m[0]}_${m[1]}`;
	}).toLowerCase();
};

export const kebabToCamelCase = s => s.replace(/-./g, x => x[1].toUpperCase())

export const snakeCaseToCamelCase = s => s.replace(/_./g, x => x[1].toUpperCase())

export const replaceUnderscoreWithSpace = (str) => str.replace(/_/g, ' ');

export const replaceDashWithSpace = (str) => str.replace(/-/g, ' ');

export const replaceDashWithUnderscore = (str) => str.replace(/-/g, '_');

export const capitalize = (str) => str.replace(/\w/, (c) => c.toUpperCase());

export const humanize = compose(replaceUnderscoreWithSpace, capitalize);

export const pascalize = compose(camelize, capitalize);

// Converts a kebab-cased string to title case with spaces - equivalent of String#titlecase in Ruby
export const titleCase = (str) => {
	return replaceDashWithSpace(str).replace(/(^\w{1})|(\s+\w{1})/g, (firstLetters) => {
		return firstLetters.toUpperCase();
	});
};

export const isNumeric = (str) => {
	if (typeof str !== 'string') {
		return false;
	}

	return !isNaN(str);
};

// check & filter empty, 'undefined,' and 'null' strings
export const check_empty = (str) => {
	if (!str || str === '' || !exists(str)) {
		return undefined;
	}

	return str;
}
