import driftHelper from 'helpers/drift';
import analytics from 'helpers/analytics';
import { check_empty } from 'helpers/strings';

const events = {
	conversationPlaybookFired: {
		name: 'conversation:playbookFired',
		description: 'Live Chat Conversation Playbook Fired',
		trackData: (data) => ({
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId
		}),
		identifyData: null
	},
	conversationPlaybookClicked: {
		name: 'conversation:playbookClicked',
		description: 'Live Chat Conversation Playbook Clicked',
		trackData: (data) => ({
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId
		}),
		identifyData: null
	},
	conversationPlaybookDismissed: {
		name: 'conversation:playbookDismissed',
		description: 'Live Chat Conversation Playbook Dismissed',
		trackData: (data) => ({
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId
		}),
		identifyData: null
	},
	conversationButtonClicked: {
		name: 'conversation:buttonClicked',
		description: 'Live Chat Conversation Response Selected',
		trackData: (data) => ({
			conversation_id: data.conversationId,
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId,
			message_body: data.buttonBody,
			drift_contact_id: data.authorId
		}),
		identifyData: null
	},
	conversationFirstInteraction: {
		name: 'conversation:firstInteraction',
		description: 'Live Chat Conversation Started',
		trackData: (data) => ({
			conversation_id: data.conversationId,
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId,
			drift_contact_id: data.authorId
		}),
		identifyData: (data) => ({
			drift_contact_id: data.authorId
		})
	},
	messageSent: {
		name: 'message:sent',
		description: 'Live Chat Message Sent',
		trackData: (data) => ({
			conversation_id: data.conversationId,
			drift_inbox_id: data.inboxId
		}),
		identifyData: null
	},
	message: {
		name: 'message',
		description: 'Live Chat Message Received',
		trackData: (data) => ({
			conversation_id: data.conversationId,
			drift_inbox_id: data.inboxId,
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId,
			agent_id: data.teamMember.id,
			agent_name: data.teamMember.name
		}),
		identifyData: null
	},
	emailCapture: {
		name: 'emailCapture',
		description: 'Live Chat Email Captured',
		trackData: (data) => ({
			conversation_id: data.data.conversationId,
			drift_playbook_id: data.data.playbookId,
			drift_campaign_id: data.data.campaignId,
			email: check_empty(data.data.email)
		}),
		identifyData: (data) => ({
			email: check_empty(data.data.email)
		})
	},
	phoneCapture: {
		name: 'phoneCapture',
		description: 'Live Chat Phone Captured',
		trackData: (data) => ({
			conversation_id: data.conversationId,
			drift_inbox_id: data.inboxId,
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId,
			phone: check_empty(data.phone)
		}),
		identifyData: (data) => ({
			phone: check_empty(data.phone)
		})
	},
	schedulingMeetingBooked: {
		name: 'scheduling:meetingBooked',
		description: 'Live Chat Meeting Booked',
		trackData: (data) => ({
			conversation_id: data.conversationId,
			drift_playbook_id: data.playbookId,
			drift_campaign_id: data.campaignId,
			meeting_timezone: data.meeting.timeZone,
			meeting_time: data.meeting.time,
			agent_id: data.teamMember.id,
			agent_name: data.teamMember.name
		}),
		identifyData: null
	},
	gdprClicked: {
		name: 'gdprClicked',
		description: 'Consent Granted',
		trackData: (data) => ({
			chat_consent: data.accepted,
			drift_contact_id: data.endUserId
		}),
		identifyData: (data) => ({
			chat_consent: data.accepted,
			drift_contact_id: data.endUserId
		})
	}
};

const trackEvent = (eventName) => {
	const event = events[eventName];
	driftHelper.attachEvent(event.name, (data) => {
		if (event.trackData) {
			const trackData = event.trackData(data);
			analytics.track(event.description, trackData);
			analytics.dataLayer({ event: event.description, ...trackData });
		}
		if (event.identifyData) {
			analytics.identify(undefined, event.identifyData(data));
		}
	});
};

const init = () => Object.keys(events).forEach(trackEvent);

export default { init };
